import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { orderBy } from "lodash"

import { localize, createMarkup } from "../utils"

import Seo from "../components/seo"
import Image from "../components/image"
import TopBar from "../components/topbar"
import Layout from "../components/layout"
import SectionChar from "../components/section-char"

const Marketing = ({ pageContext, intl }) => {
  const marketingData = pageContext.page
  const blocksData = pageContext.blocks

  const page = localize(marketingData, intl.locale)
  console.log(page)
  const category =
    marketingData.category && localize(marketingData.category, intl.locale)
  const productLine = marketingData.category
    ? localize(marketingData.category.product_line, intl.locale)
    : marketingData.linea

  const usaBlocchi = pageContext.page.usa_blocchi
  let categorySections = []
  if (usaBlocchi && blocksData) {
    const localizedBlocks = blocksData.map(blockData =>
      localize(blockData, intl.locale)
    )
    categorySections = orderBy(
      localizedBlocks.filter(
        block =>
          block.page === "categoria" &&
          parseInt(block.section) ===
            parseInt(category?.directusId || productLine?.directusId)
      ),
      "order"
    )
  }

  const bottonePageLink = page.bottone_link
  return (
    <Layout>
      <Seo
        title={page.title}
        description={page.testo_menu}
        imageUrl={page.immagine}
      />
      <TopBar title={page.title}>
        <p className="p-marketing__abstract">{page.testo_menu}</p>
      </TopBar>

      {page.video_link && (
        <section className="a-section">
          <div className={`a-container`}>
            <div class="c-video">
              <iframe
                title="video"
                width="100%"
                height="100%"
                src={`https://www.youtube-nocookie.com/embed/${
                  (page.video_link || "").split("?v=")[1]
                }?controls=0`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
              ></iframe>
            </div>
          </div>
        </section>
      )}

      {usaBlocchi && categorySections.length > 0 ? (
        <div className="a-container p-marketing-container">
          {categorySections.map(section => {
            return <SectionChar section={section}></SectionChar>
          })}
        </div>
      ) : (
        <section className="a-section">
          <div className={`a-container`}>
            <div className="p-marketing__intro">
              <div dangerouslySetInnerHTML={createMarkup(page.body)} />
              <div className="p-marketing__intro__image">
                <Image image={page.immagine} />
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="a-section bg-dark">
        <div className={`a-container`}>
          <div className="p-marketing__products">
            <div className="p-marketing__products__inner">
              <h4>{page.titolo_piede}</h4>
              <p className="p-marketing__products__description">
                {page.descrizione_piede}
              </p>
            </div>

            <div className="p-marketing__products__image">
              {/* <img src="/test_products.png" /> */}
              <Image image={page.immagine_piede} />
            </div>
            {((productLine && category) || bottonePageLink) && (
              <button
                class="a-btn a-btn--primary"
                onClick={() =>
                  navigate(
                    bottonePageLink ||
                      `/prodotti/${productLine.slug}/${category.slug}?mkt=1`
                  )
                }
              >
                {intl.formatMessage({ id: "terms.discover_products" })}
              </button>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default injectIntl(Marketing)
